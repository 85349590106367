.header {
  width: 100%;
  background: #ffdfdf;
  height: 120px;
  position: absolute;
  top: 0px;
  left: 0px;
  z-index: 100;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
}
