@import url(https://fonts.googleapis.com/css?family=Lato&display=swap);
body {
  margin: 0;
  font-family: "Lato", sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background-color: #ffdfdf;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

.Header_header__6J3mP {
  width: 100%;
  background: #ffdfdf;
  height: 120px;
  position: absolute;
  top: 0px;
  left: 0px;
  z-index: 100;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
}

.First_first__3BPkO {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
}

.First_text__1ybrx {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

